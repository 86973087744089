
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { Grid, GridNoRecords } from "@progress/kendo-vue-grid";

import { StudentProgressModel, StudentModel } from "@/models";
import { AppActionTypes, useStore } from "@/store";

import { courseColumns } from "@/columns";
import {
    getProficiencyClassName,
    mapStudentCourseOptions,
    mapStudentOptions
} from "@/helpers";

import ProgressBarVue from "@/components/ProgressBar.vue";
import PageGreetingVue from "@/components/PageGreeting.vue";
import ProgressGaugeVue from "@/components/ProgressGauge.vue";
import NoStudentsBannerMessageVue from "@/components/NoStudentsBannerMessage.vue";

export default defineComponent({
    components: {
        "page-greeting": PageGreetingVue,
        "progress-bar": ProgressBarVue,
        "progress-gauge": ProgressGaugeVue,
        "grid": Grid,
        "grid-no-records": GridNoRecords,
        "no-students-banner-message": NoStudentsBannerMessageVue
    },
    setup() {
        const { state, getters, dispatch } = useStore();

        const title = "Report Cards 🏫";
        const message = "Select a student and view their learning progress.";

        const students = ref<StudentModel[]>([]);
        const selectedStudentId = ref<number | null>(null);

        const selectedStudent = computed(() =>
            students.value?.find(
                (student) => student.id === selectedStudentId.value
            )
        );
        const selectedProfileImageUrl = computed(
            () => selectedStudent.value?.profileImageUrl ?? null
        );

        const studentOptions = computed(() =>
            mapStudentOptions(students.value ?? [])
        );

        // Progress
        // Local cached progress - we're not using vuex so that the user can see updates without refreshing the page.
        // This will be destroyed after navigating away, so it will be retrieved again when coming back and up to date.
        // This helps avoid duplicate requests (it was making two request on the initial page load)
        const studentProgresses = ref<Record<number, StudentProgressModel>>({});

        const studentProgress = computed(() =>
            selectedStudentId.value === null
                ? null
                : studentProgresses.value[selectedStudentId.value]
        );
        const selectedCourseId = ref<number | null>(null);

        const selectedCourse = computed(() =>
            studentProgress.value?.courses?.find(
                (progress) => progress.courseId === selectedCourseId.value
            )
        );

        /**
         * Creates a select list of courses to use for the course selection control in the template
         */
        const courseOptions = computed(() =>
            mapStudentCourseOptions(studentProgress.value?.courses)
        );

        const hasStudents = computed(() => state.settings?.hasStudents);

        /**
         * Gets the students and selects the first one
         */
        async function getStudents(): Promise<void> {
            students.value = await dispatch(AppActionTypes.getStudents);

            // Default select the first student returned.
            if (students.value && students.value.length > 0) {
                selectedStudentId.value = students.value[0].id || null;
            }
        }

        /**
         * Gets the student progress for the selected student. Will use a locally cached version if available.
         */
        async function getStudentProgress(): Promise<void> {
            if (!selectedStudentId.value) {
                return;
            }

            // use a cached value if it exists
            if (studentProgresses.value[selectedStudentId.value]) {
                return;
            }

            const newStudentProgress = await dispatch(
                AppActionTypes.getStudentProgress,
                { id: selectedStudentId.value }
            );

            if (newStudentProgress) {
                studentProgresses.value[selectedStudentId.value] =
                    newStudentProgress;
            }

            // Default select the first course returned.
            if (
                newStudentProgress &&
                newStudentProgress.courses &&
                newStudentProgress.courses.length > 0
            ) {
                selectedCourseId.value =
                    newStudentProgress.courses[0].courseId || null;
            }
        }

        watch(
            () => selectedStudentId.value,
            (newValue, oldValue) => {
                // get the new student progress if the student id has changed
                if (newValue && newValue !== oldValue) {
                    getStudentProgress();
                }
            }
        );

        onMounted(() => {
            getStudents();
        });

        return {
            selectedStudentId,
            selectedProfileImageUrl,
            studentOptions,
            selectedCourseId,
            selectedCourse,
            courseOptions,
            hasStudents,
            studentProgresses,
            studentProgress,
            courseColumns,
            loading: computed(() => getters.loading),
            title,
            message,
            getProficiencyClassName,
            rank: computed(() => studentProgress.value?.rank || {})
        };
    }
});
