<template>
    <div class="alert alert-info" role="alert">
        <h2 class="h5 font-weight-bold">
            Now that you've created your exclusive eSpired account, it's time to
            explore and make the most of your experience.
        </h2>
        <slot>
            <ul>
                <li>Personalize your profile</li>
                <li>Add your students and manage their progress</li>
                <li>Submit projects and ideas</li>
                <li>And so much more…</li>
            </ul>
        </slot>
    </div>
</template>
<style lang="scss" scoped>
.alert {
    color: $black;
    border: 0px;
}
</style>
