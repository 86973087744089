import { GridColumnProps } from "@progress/kendo-vue-grid";

export const courseColumns: Array<GridColumnProps> = [
    {
        field: "quizName",
        title: "Quiz Name",
        filterable: false,
        sortable: false
    },
    {
        field: "lessonName",
        title: "Lesson",
        filterable: false,
        sortable: false
    },
    {
        field: "proficiency",
        title: "Proficiency",
        filterable: false,
        sortable: false,
        cell: "proficiencyCell"
    },
    {
        field: "pointsDisplay",
        title: "Correct Answers",
        filterable: false,
        sortable: false
    },
    {
        field: "pointsPossible",
        title: "Total Questions",
        filterable: false,
        sortable: false
    }
];
